button {
  font-family: inherit;
}

.bubbly {
  position: relative;
  padding: 0.25em 0.5em;
  background: #e7e7e7;
  display: inline-block;
  text-align: center;
  border-radius: 12px;
  font-size: 120%;
  border: var(--border-thin);
  text-shadow: 2px 2px 2px #00000040;
  box-shadow: inset -6px -6px 0px 0px #cfcfcf, var(--shadow-clickable);
  text-decoration: none;
}

.bubbly:not([disabled]):hover {
  background: #ffffff;
  cursor: var(--cursor-hover);
}

.bubbly:not([disabled]):active {
  background: #e7e7e7;
  box-shadow: inset 6px 6px 0px 0px #cfcfcf, var(--shadow-clickable);
}

.bubbly.green {
  box-shadow: inset -6px -6px 0px 0px #65a013, var(--shadow-clickable);
  background: #92cc41;
  color: white;
}

.bubbly.green:not([disabled]):hover {
  background: #76c442;
}

.bubbly.green:not([disabled]):active {
  background: #92cc41;
  box-shadow: inset 6px 6px 0px 0px #65a013, var(--shadow-clickable);
}

.bubbly.orange {
  background: #f7d51d;
  box-shadow: inset -6px -6px 0px 0px #c9ae17, var(--shadow-clickable);
  color: black;
}

.bubbly.orange:not([disabled]):hover {
  background: #e7c819;
}

.bubbly.orange:not([disabled]):active {
  background: #f7d51d;
  box-shadow: inset 6px 6px 0px 0px #c9ae17, var(--shadow-clickable);
}

.bubbly.red {
  background: #e76e55;
  box-shadow: inset -6px -6px 0px 0px #a43436, var(--shadow-clickable);
  color: white;
}

.bubbly.red:not([disabled]):hover {
  background: #c25c48;
}

.bubbly.red:not([disabled]):active {
  background: #761c1e;
  box-shadow: inset 6px 6px 0px 0px #a43436, var(--shadow-clickable);
}

.bubbly.blue {
  background: #109fff;
  box-shadow: inset -6px -6px 0px 0px #006bb3, var(--shadow-clickable);
  color: white;
}

.bubbly.blue:not([disabled]):hover {
  background: var(--color-bg-accent);
}

.bubbly.blue:not([disabled]):active {
  background: #109fff;
  box-shadow: inset 6px 6px 0px 0px #108de0, var(--shadow-clickable);
}

.bubbly.pink {
  background: #ee2097;
  color: white;
  box-shadow: inset -6px -6px 0px 0px #902c74, var(--shadow-clickable);
}

.bubbly.pink:not([disabled]):hover {
  background: #eb55ac;
}

.bubbly.pink:not([disabled]):active {
  background: #ee2097;
  box-shadow: inset 6px 6px 0px 0px #902c74;
}

.bubbly.dark {
  background: #444;
  color: white;
  box-shadow: inset -6px -6px 0px 0px #333, var(--shadow-clickable);
}

.bubbly.dark:not([disabled]):hover {
  background: #555;
}

.bubbly.dark:not([disabled]):active {
  background: #444;
  box-shadow: inset 6px 6px 0px 0px #333;
}

.bubbly.discord {
  background: #444ebe;
  color: white;
  box-shadow: inset -6px -6px 0px 0px #2d347e, var(--shadow-clickable);
}
.bubbly.discord:not([disabled]):hover {
  background: #4a54bf;
}

.bubbly.discord:not([disabled]):active {
  background: #444ebe;
  box-shadow: inset 6px 6px 0px 0px #2d347e, var(--shadow-clickable);
}

.bubbly[disabled] {
  background: #d3d3d3;
  box-shadow: inset -6px -6px 0px 0px #adafbc;
  color: #757575;
  opacity: 0.6;
  cursor: not-allowed;
}

.bubbly > img:first-child {
  height: 1.4em;
  display: inline-block;
  vertical-align: text-top;
  margin-right: 0.25em;
}

.bubbly.loading::after {
  content: "";
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
